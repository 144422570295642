import { Apis } from "bitsharesjs-ws";

/** This file centralized customization and branding efforts throughout the whole wallet and is meant to facilitate
 *  the process.
 *
 *  @author Stefan Schiessl <stefan.schiessl@blockchainprojectsbv.com>
 */

/**
 * Determine if we are running on testnet or mainnet
 * @private
 */
function _isTestnet() {
    var chainId = (Apis.instance().chain_id || "4018d784").substr(0, 8);
    if (chainId === "4018d784") {
        return false;
    } else {
        // treat every other chain as testnet, exact would be chainId === "39f5e2ed"
        return true;
    }
}

/**
 * Wallet name that is used throughout the UI and also in translations
 * @returns {string}
 */
export function getWalletName() {
    return "XBTS";
}

/**
 * URL of this wallet
 * @returns {string}
 */
export function getWalletURL() {
    return "https://ex.xbts.io";
}

/**
 * Returns faucet information
 *
 * @returns {{url: string, show: boolean}}
 */
export function getFaucet() {

    return {
        url: "https://faucet.xbts.io",
        show: false,
        editable: false,
        referrer: "xbtsx"
    };

    /*
    return {
        url: "https://faucet.bitshares.eu/onboarding", // 2017-12-infrastructure worker proposal
        show: true,
        editable: true,
        referrer: "onboarding.bitshares.foundation"
    };
    */
}

export function getTestFaucet() {
    // fixme should be solved by introducing _isTestnet into getFaucet and fixing the mess in the Settings when fetching faucet address
    return {
        url: "https://testnet-faucet.xbts.io", // operated as a contribution by xbts
        show: true,
        editable: true
    };
}

/**
 * Logo that is used throughout the UI
 * @returns {*}
 */
export function getLogo() {
    return require("assets/logo-ico-blue.png");
    //return require("assets/logo-year.png");
}

export function getLogoSmall() {
    return require("assets/logo-small.png");
}

export function getLogoDark() {
    return require("assets/icon-dark.png");
}

/**
 * Default set theme for the UI
 * @returns {string}
 */
export function getDefaultTheme() {
    // possible ["darkTheme", "lightTheme", "midnightTheme"]
    return "midnightTheme";
}

/**
 * Default login method. Either "password" (for cloud login mode) or "wallet" (for local wallet mode)
 * @returns {string}
 */
export function getDefaultLogin() {
    // possible: one of "password", "wallet"
    return "password";
}

/**
 * Default units used by the UI
 *
 * @returns {[string,string,string,string,string,string]}
 */
export function getUnits() {
    if (_isTestnet()) {
        return ["TEST"];
    } else {
        return ["BTS", "XBTSX.USDT", "XBTSX.RUB", "CNY", "EUR", "XBTSX.BTC"];
    }
}

/**
 * These are the highlighted bases in "My Markets" of the exchange
 *
 * @returns {[string]}
 */

export function getMyMarketsBases() {
    return ["XBTSX.USDT", "BTS", "XBTSX.BTC", "XBTSX.USDC", "XBTSX.ETH", "XBTSX.STH"];
}

export function getScamTokens() {
    return ["KUSAMA", "AUDIO", "CLOUDCOIN", "DRAHMO"];
}

/**
 * These are the default quotes that are shown after selecting a base
 *
 * @returns {[string]}
 */
export function getMyMarketsQuotes() {
    var tokens = {
        nativeTokens: [
        //"BTC",
        "USD", "BTS", "CNY", "JPY", "GBP", "AUD", "KRW", "GOLD", "SILVER", "CHF", "CAD", "RUBLE"],
        bridgeTokens: [],
        gdexTokens: [],
        openledgerTokens: [],
        rudexTokens: [],
        sparkTokens: [],
        xbtsxTokens: ["XBTSX.STH", "XBTSX.POST", "XBTSX.DOGE", "XBTSX.BTC", "XBTSX.LTC", "XBTSX.DASH", "XBTSX.BTG", "XBTSX.NVC", "XBTSX.42", "XBTSX.NMC", "XBTSX.WAVES", "XBTSX.ETH", "XBTSX.ONION", "XBTSX.EGC", "XBTSX.BCH", "XBTSX.MDL", "XBTSX.SKY", "XBTSX.GRS", "XBTSX.XBB", "XBTSX.EXR", "XBTSX.AXAI", "XBTSX.TUSD", "XBTSX.USDT", "XBTSX.RVN", "XBTSX.TRD", "XBTSX.SCH", "XBTSX.USDN", "XBTSX.EOS", "XBTSX.RUB", "XBTSX.USD", "XBTSX.EUR", "XBTSX.VTC", "XBTSX.USDC", "XBTSX.BAT", "XBTSX.ATRI", "XBTSX.BNB", "XBTSX.TRX", "XBTSX.HT", "XBTSX.XRP", "XBTSX.HIVE", "XBTSX.EMC", "XBTSX.NESS", "XBTSX.PPC", "XBTSX.SHIB", "XBTSX.HBD", "XBTSX.PIVX", "XBTSX.AVAX", "XBTSX.RTM", "XBTSX.XAUT", "XBTSX.MATIC", "XBTSX.HVQ", "XBTSX.TCG", "XBTSX.NCH", "XBTSX.LUNR", "XBTSX.RDD", "XBTSX.BSV", "XBTSX.MANA", "XBTSX.AUR", "XBTSX.ETC", "XBTSX.ASIC", "XBTSX.VITE", "XBTSX.GODS", "XBTSX.FLUX", "XBTSX.ZEC", "XBTSX.QTUM", "XBTSX.ZIL", "XBTSX.KMD", "XBTSX.XCCX", "XBTSX.PEPE", "XBTSX.RXD", "XBTSX.HERO", "XBTSX.DAI", "XBTSX.WRAM", "XBTSX.TON", "XBTSX.DPR", "XBTSX.XCH", "XBTSX.PEP"],
        otherTokens: ["EVRAZ", "CRUDE.NGN", "BEOS", "TWENTIX", "BTWTY", "YOYOW", "DEFI", "QUINT", "QUINT.GOLDBACK", "TWENTIX.HUSDMM"]
    };

    var allTokens = [];
    for (var type in tokens) {
        allTokens = allTokens.concat(tokens[type]);
    }
    return allTokens;
}

/**
 * The featured markets displayed on the landing page of the UI
 *
 * @returns {list of string tuples}
 */
export function getFeaturedMarkets() {
    var quotes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    return [["XBTSX.BTC", "XBTSX.STH"], ["XBTSX.BTC", "XBTSX.ETH"], ["XBTSX.BTC", "XBTSX.DASH"], ["XBTSX.BTC", "XBTSX.DOGE"], ["XBTSX.BTC", "XBTSX.LTC"], ["BTS", "XBTSX.BTC"]].filter(function (a) {
        if (!quotes.length) return true;
        return quotes.indexOf(a[0]) !== -1;
    });
}

/**
 * Recognized namespaces of assets
 *
 * @returns {[string,string,string,string,string,string,string]}
 */
export function getAssetNamespaces() {
    return [
    //"OPEN.",
    //"RUDEX.",
    //"GDEX.",
    "XBTSX."];
}

/**
 * These namespaces will be hidden to the user, this may include "bit" for BitAssets
 * @returns {[string,string]}
 */
export function getAssetHideNamespaces() {
    // e..g "OPEN.", "bit"
    return ["XBTSX."];
}

/**
 * Allowed gateways that the user will be able to choose from in Deposit Withdraw modal
 * @param gateway
 * @returns {boolean}
 */
export function allowedGateway(gateway) {
    return [
    //"GDEX",
    "XBTSX", "WAVES",
    //"BNB",
    "ETH",
    //"HECO",
    "BSC", "EOS"].indexOf(gateway) >= 0;
}

export function getSupportedLanguages() {
    // not yet supported
}

export function getAllowedLogins() {
    // possible: list containing any combination of ["password", "wallet"]
    return ["password", "wallet"];
}

export function getConfigurationAsset() {
    var assetSymbol = null;
    if (_isTestnet()) {
        assetSymbol = "NOTIFICATIONS";
    } else {
        assetSymbol = "TEST";
    }
    // explanation will be parsed out of the asset description (via split)
    return {
        symbol: assetSymbol,
        explanation: "This asset is used for decentralized configuration of the BitShares UI placed under bitshares.org."
    };
}